import React from "react";
import PropTypes from "prop-types";

import {
  Alert,
  Flex,
  Branding
} from "@nulogy/components";

/* eslint-disable react/no-danger */
const AuthLayout = ({
  errorMessage,
  noticeMessage,
  children
}) => (
  <Flex
    minHeight='100vh'
    justifyContent='center'
    flexDirection='column'
    alignItems='center'
    bg={{ extraSmall: "white", small: "whiteGrey" }}
    width='100%'
  >
    <Flex width='100%' maxWidth='384px' alignItems='center' justifyContent='center' flexGrow='1'>
      <Flex
        height={{ extraSmall: "100%", small: "auto" }}
        maxWidth='384px'
        width='100%'
        borderRadius={{ extraSmall: null, small: "medium" }}
        boxShadow={{ extraSmall: null, small: "small" }}
        px={{ extraSmall: "x2", small: "x4" }}
        py={{ extraSmall: "x3", small: "x5" }}
        bg='white'
        my='x2'
        flexDirection='column'
        alignItems='center'
      >
        <Branding
          withLine
          size='large'
          alignment='center'
          marginBottom="x2"
        />

        {
          errorMessage &&
          <Alert type='danger' mb='x3' style={{ width: "100%" }}>
            <span dangerouslySetInnerHTML={{ __html: errorMessage }} />
          </Alert>
        }
        {
          noticeMessage &&
          <Alert type='informative' mb='x3' style={{ width: "100%" }}>
            <span dangerouslySetInnerHTML={{ __html: noticeMessage }} />
          </Alert>
        }

        {children}

      </Flex>
    </Flex>
  </Flex>
);
/* eslint-enable react/no-danger */

AuthLayout.propTypes = {
  errorMessage: PropTypes.string,
  noticeMessage: PropTypes.string,
  currentYear: PropTypes.string,
  children: PropTypes.node.isRequired
};

export default AuthLayout;
